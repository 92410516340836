import { useState } from 'react';
import './App.css';

function App() {
const [submitted, setSubmitted] = useState(false)
const [firstName, setfName] = useState('')
const [lastName, setlName] = useState('')
const [phoneInput, setPhoneInput] = useState('')
const [email, setEmail] = useState('')
const [jobTitle, setJobTitle] = useState('')
const [copied, setCopied] = useState(false)


function copyElementToClipboard(element) {
  window.getSelection().removeAllRanges();
  let range = document.createRange();
  range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
  window.getSelection().addRange(range);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
}

const captureValues = (event) => {
  event.preventDefault()
    setfName(event.target[0].value)
    setlName(event.target[1].value)
    setEmail(event.target[2].value)
    setPhoneInput(event.target[3].value)
    setJobTitle(event.target[4].value)
  }

  function formatPhone(string) {
    var numsStr = string.replace(/[^0-9]/g, '')
    return parseInt(numsStr)
  }
  const phoneFormatted = phoneInput ? formatPhone(phoneInput) : undefined
  return (
    <>
    <h1 style={{textAlign: 'center'}}>ISSA Email Signature Generator</h1>
    <div className='wrapper'>

      <form className="form" onSubmit={captureValues}>
        <p><b>Add your information below:</b></p>
        <input id="firstName" placeholder='First Name' required={true}/>
        <input id="lastName" placeholder='Last Name' required={true}/>
        <input id="email" placeholder='Email' required={true}/>
        <input id="phone" placeholder='Phone (optional)' type='tel' required={false}/>
        <input id="jobTile" placeholder='Job Title' required={true}/>
        <div className='submit-reset'>
        <input type="submit" value="Submit" className='submit-btn' onClick={() => {
          setSubmitted(true)
          setCopied(false)
        }}/>
        <input type="reset" value="Reset" className='reset-btn' onClick={() => {
          setSubmitted(false)
          setCopied(false)
        }}/>
        </div>
      </form>

      <hr/> 
      <div className='output'>
      {!submitted && (firstName || lastName || jobTitle || email === '') && (
        <p style={{textAlign: 'center'}}><b>Fill out the form fields and click 'Submit'!</b></p>
      )}

      {submitted && (firstName || lastName || jobTitle || email !== '') && (
      <>
      <div id="signatureWrapper">
      <div id="signatureContainer">
      <table id="emailSignature" width="375px" bgcolor="#ffffff" border="0" cellPadding="0" cellSpacing="0" style={{backgroundColor: '#ffffff',borderCollapse:'collapse',borderSpacing:'0',borderRight:'10px solid #ffc020'}}>
    <tbody>
    <tr>
      <td colSpan="2" height="15"></td>
    </tr>
    <tr>
      <td valign="top" width="247">
        <table width="247" border="0" style={{borderCollapse: 'collapse',borderSpacing: '0',borderRight: '3px solid #ffc020'}}>
          <tbody>
          <tr>
            <td style={{padding: '0 8px'}}>
              <p style={{fontFamily:'Arial Black',lineHeight: '1',fontSize: '22px',color: '#000000', margin: '0'}} id="firstNameDisplay">{firstName}</p>
              <p style={{fontFamily: 'Arial Black',lineHeight: '1',fontSize: '18px',color:'#2c2c2c',margin: '0'}} id="lastNameDisplay">{lastName}</p>
            </td>
          </tr>
          <tr>
            <td style={{padding: '15px 0'}}>
              <p style={{background: '#ffc020', borderRadius: '30px',padding: '5px 8px',fontFamily:'Arial',fontWeight: '700',lineHeight: '1',fontSize: '12px',color:'#000000',margin: '0',display: 'inline-flex'}} id="jobTitleDisplay">{jobTitle}</p>
            </td>
          </tr>
          <tr>
            <td>
              <table width="235" border="0" style={{borderCollapse: 'collapse',borderSpacing: '0'}}>
                <tbody>
                  {phoneInput && (
                <tr id="phoneNumber">
                  <td width="22" height="22" valign="top"><img src="https://www.issatrainer.com/globalwebsitefiles/images/email-signature/icon-phone.gif" width="14" height="15" alt="Phone"/></td>
                  <td valign="middle"><p style={{fontFamily:'Arial',fontSize: '13px', color: '#000000',margin: '0', textDecoration: 'none'}} id="phoneDisplay"><a href={`tel:${phoneFormatted}`} rel="noreferrer" target="_blank" style={{color: '#000000',textDecoration: 'none'}}>{phoneInput}</a></p></td>
                </tr>
                )}
                <tr>
                  <td width="22" height="22" valign="top"><img src="https://www.issatrainer.com/globalwebsitefiles/images/email-signature/icon-email.gif" width="14" height="15" alt="Email"/></td>
                  <td valign="middle"><p style={{fontFamily: 'Arial',fontSize: '13px', margin: '0',textDecoration: 'none'}} id="emailDisplay"><a href={`mailto:${email}`} rel="noreferrer" target="_blank" style={{color: '#000000',textDecoration: 'none'}}>{email}</a></p></td>
                </tr>
                <tr>
                  <td width="22" height="22" valign="top"><img src="https://www.issatrainer.com/globalwebsitefiles/images/email-signature/icon-web.gif" width="14" height="15" alt="Website"/></td>
                  <td valign="middle"><p style={{fontSize: '13px',fontFamily:'Arial',margin: '0',textDecoration: 'none'}}><a href="https://www.issaonline.com/" rel="noreferrer" target="_blank" style={{color: '#000000',textDecoration: 'none'}}>www.issaonline.com</a></p></td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
      </td>
      <td width="114" style={{padding: "0 1px 0 0"}} align="center" valign="middle"><img src="https://images.ctfassets.net/qw8ps43tg2ux/55s9jCeX42k88DHO9UIRsF/9a401b824f2ad181bc07ce7738c6ec1b/issa-bptw-2023.jpg" width="100" alt="International Sports Sciences Association - 2023 Best Places To Work"/></td>


</tr>
    <tr>
      <td colSpan="2" height="15"></td>
    </tr>
  </tbody>
  </table>
   <br/>
  <table width="100%" border="0" cellPadding="0"cellSpacing="0">
    <tbody>
    <tr>
      <td>
        <p className='confidentiality' style={{fontFamily: 'Arial',color: '#888888',fontSize: '8px',fontStyle: 'italic', paddingBottom: '20px'}}>This email and any attachments may contain private, confidential, and privileged material for the sole use of the intended recipient. If you are not the intended recipient, please immediately delete this email and any attachments.</p>
      </td>
    </tr>
    </tbody>
  </table>
  </div>
  <div className='copyButtons'>
  {!copied && (
  <button onClick={() => {
    copyElementToClipboard('signatureContainer')
    setCopied(true)
    }} className="copy-btn">Copy to Clipboard</button>
    )}
      {copied && (
  <button onClick={() => {
    setCopied(false)
    }} className="post-copy">Copied!</button>
    )}
    </div>
    </div>
        </>
        )}
      </div>
    </div>
    <div className='how-to'>
    <h2>How to add your signature to your Gmail account:</h2>
    <ol>
    <li><p>Fill out the form above, and click 'Submit'.</p></li>
    <li><p>Copy the signature you generated and open your Gmail account.</p></li>
    <li><p>Go to 'Settings. This should appear has a cog icon in the top right corner of your window.</p></li>
    <li><p>Under 'Quick Settings', click 'See all settings'.</p></li>
    <li><p>In the 'General tab', scroll down until you see 'Signature'.</p></li>
    <li><p>Click '+ Create new', and give your signature a name.</p></li>
    <li><p>In the text box that appears on the right side, paste in the signature you copied to your clipboard in step 1. </p></li>
    <li><p>After pasting, open the dropdown(s) under 'Signature defaults' and select the signature you just created. </p></li>
    <li><p>Scroll down to the bottom of the page and click 'Save Changes'. Your new email signature will appear on the next mesage you compose.</p></li>
    </ol>
    </div>
    </>
  )
}

export default App;
